import { useMainStore } from 'src/main-store/useMainStore'

import { USER_ENDPOINT } from '../../auth'
import { getRequestInit } from '../../fetch-data'
import { FetchMiddleware } from '../fetchMiddleware.types'

const HTTP_UNAUTHORIZED_STATUS_CODE = 401

const PUBLIC_CATALOG_RELEVANT_PATH_SEGMENT = 'catalog'

let fetchController: AbortController | null = null

export const checkSessionExpiredMiddleware: FetchMiddleware = response => {
  const secondPathSegment = window.location.pathname.split('/')[2]

  if (
    response.status === HTTP_UNAUTHORIZED_STATUS_CODE &&
    secondPathSegment !== PUBLIC_CATALOG_RELEVANT_PATH_SEGMENT
  ) {
    const { isSessionExpired } = useMainStore.getState()

    if (!isSessionExpired) {
      fetchController?.abort()
      fetchController = new window.AbortController()
      fetch(USER_ENDPOINT, { ...getRequestInit({ method: 'GET' }), signal: fetchController.signal })
        .then(res => {
          fetchController = null

          if (res.status === HTTP_UNAUTHORIZED_STATUS_CODE) {
            useMainStore.setState({ isSessionExpired: true })
          }
        })
        .catch(error => {
          if (/aborted/.test(error.message)) {
            return Promise.resolve()
          }

          throw Error(error?.message || `Call to ${USER_ENDPOINT} failed`)
        })
    }
  }

  return response
}
