import { useMainStore } from 'src/main-store/useMainStore'

import { FetchMiddleware } from '../fetchMiddleware.types'

const HTTP_AUTHENTICATED_WITH_DIFFERENT_USER_STATUS_CODE = 403

const DJANGO_AUTHENTICATED_WITH_DIFFERENT_USER_ERROR_CODE = 2005

export const checkAuthenticatedWithDifferentUserMiddleware: FetchMiddleware = response => {
  if (
    response.status === HTTP_AUTHENTICATED_WITH_DIFFERENT_USER_STATUS_CODE &&
    window.RELEASE_TOGGLE_SHOW_MODAL_WHEN_AUTHENTICATED_WITH_DIFFERENT_USER === 'True'
  ) {
    response
      .json()
      .then(body => {
        if (body.code === DJANGO_AUTHENTICATED_WITH_DIFFERENT_USER_ERROR_CODE) {
          useMainStore.setState({ isAuthenticatedWithDifferentUser: true })
        }
      })
      .catch(error => {
        throw Error(error?.message || 'An error has occurred.')
      })
  }

  return response
}
