import styled, { css } from 'styled-components'

import { Variant } from '../../../themes'
import { CheckboxFormatType } from './Checkbox'

type CheckboxContainerProps = {
  disabled: boolean
  format: CheckboxFormatType
  indeterminated: boolean
  variant?: Variant
}

const CHECKBOX_SIZE = '17px'

export const CheckboxInput = styled.input.attrs(() => ({ type: 'checkbox' }))`
  opacity: 0;
  position: absolute;
  z-index: -1;
`

export const CheckboxLabel = styled.label<{
  hasContent: boolean
  format: CheckboxFormatType
  variant?: Variant
}>`
  user-select: none;
  display: flex;
  padding: ${props => props.theme.spacings.small} calc(1.5 * ${props => props.theme.spacings.small});
  align-items: center;
  cursor: pointer;
  /* TODO: Could be deleted once semantic will be removed from the app */
  box-sizing: initial;

  & > span {
    min-height: 15px;
    padding: ${props => (props.format === 'toggled' ? 0 : `0 ${props.theme.spacings.small} 0 0`)};

    & > i,
    & > svg {
      padding-right: ${props => props.theme.spacings.small};
    }
  }

  ${props =>
    props.format !== 'toggled' &&
    `
      &:before {
        display: inline-block;
        content: '';
        height: ${CHECKBOX_SIZE};
        width: ${CHECKBOX_SIZE};
        margin-right: ${props.hasContent ? props.theme.spacings.xsmall : 0};
        border: 1px solid ${
          props.variant ? props.theme.colors[props.variant].light : props.theme.colors.neutral.base
        };
        border-radius: ${props.theme.dimensions.borderRadius};
      }
    `}
`

const disabledStyle = css<{ disabled: boolean; variant?: Variant }>`
  ${props =>
    props.disabled &&
    `
      ${CheckboxLabel} {
        cursor: not-allowed;
        opacity: ${props.theme.opacities.disabled};
      }

      ${CheckboxInput}:focus
      + ${CheckboxLabel}:before,
        ${CheckboxLabel}:hover:before,
        &:hover
        > ${CheckboxLabel} {
        border-color: ${
          props.variant ? props.theme.colors[props.variant].light : props.theme.colors.neutral.base
        };
      }
    `}
`

const outlinedStyle = css<{ format: CheckboxFormatType; variant?: Variant }>`
  ${props =>
    props.format === 'outlined' &&
    `
      height: ${props.theme.dimensions.inputHeight};

      ${CheckboxInput}:checked {
        & + ${CheckboxLabel} {
          background-color: ${props.theme.colors[props.variant || 'secondary'].lighter};
          & > svg {
            left: -22px;
          }
        }
      }

      ${CheckboxInput}:focus + ${CheckboxLabel} {
        border: 1px solid ${props.theme.colors[props.variant || 'primary'].base};
      }

      ${CheckboxLabel} {
        align-items: center;
        border: 1px solid ${
          props.variant ? props.theme.colors[props.variant].light : props.theme.colors.neutral.base
        };
        border-radius: ${props.theme.dimensions.borderRadius};
        height: ${props.theme.dimensions.inputHeight};

        background-color: ${
          props.variant ? props.theme.colors[props.variant].lighter : props.theme.colors.white.base
        };

        & > span {
          line-height: 15px;
        }

        &:before {
          margin-right: ${props.theme.spacings.small};
        }
      }

      &:hover > ${CheckboxLabel} {
        border-color: ${props.theme.colors[props.variant || 'primary'].base};
      }
    `}
`

const indeterminatedStyle = css<{
  indeterminated: boolean
  format: CheckboxFormatType
  variant?: Variant
}>`
  ${props =>
    props.indeterminated &&
    `
      ${CheckboxLabel}:before {
        border-color: ${props.theme.colors[props.variant || 'primary'].base};
        background-color: ${props.theme.colors[props.variant || 'primary'].base};
      }
      ${CheckboxLabel} > svg {
        color: white;
        top: ${props.format === 'outlined' ? '14px' : '10px'};
        left: ${props.format === 'outlined' ? '16px' : '14px'};
        position: absolute;
        z-index: 1;
      }
    `}
`

const toggledStyle = css<{ format: CheckboxFormatType; variant?: Variant }>`
  ${props =>
    props.format === 'toggled' &&
    `
      height: ${props.theme.dimensions.inputHeight};

      ${CheckboxLabel} {
        background-color: ${
          props.variant ? props.theme.colors[props.variant].lighter : props.theme.colors.white.base
        };
        border: 1px solid ${
          props.variant ? props.theme.colors[props.variant].light : props.theme.colors.neutral.base
        };
        border-radius: ${props.theme.dimensions.borderRadius};
        height: ${props.theme.dimensions.inputHeight};
      }

      ${CheckboxInput}:checked + ${CheckboxLabel} {
          border-color: ${props.theme.colors[props.variant || 'primary'].base};
          box-shadow: inset 0 0 0 1px ${props.theme.colors[props.variant || 'primary'].base};
          background-color: ${props.theme.colors[props.variant || 'secondary'].lighter};
          z-index: 2;

          & > svg {
            position: absolute;
            height: 10px;
            width: 10px;
            right: 1px;
            top: 1px;
            z-index: 1;
            color: white;
          }

          & > span {
            margin-left: 0;
          }
        }
      }

      &:hover > ${CheckboxLabel} {
        border-color: ${props.theme.colors[props.variant || 'primary'].base};
      }
    `}
`

export const CheckboxContainer = styled('div')<CheckboxContainerProps>`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  & > *:not(:last-child) {
    margin-bottom: ${props => props.theme.spacings.xsmall};
  }

  ${CheckboxInput}:checked {
    & + ${CheckboxLabel}:before {
      border-color: ${props => props.theme.colors[props.variant || 'primary'].base};
      box-shadow: 0 0 0 1px ${props => props.theme.colors[props.variant || 'primary'].base};
      background-color: ${props => props.theme.colors[props.variant || 'secondary'].lighter};
    }

    & + ${CheckboxLabel} > svg {
      position: relative;
      height: 12px;
      width: 12px;
      left: -19px;
      top: 0;
      z-index: 1;
    }

    & + ${CheckboxLabel} > span {
      margin-left: -12px;
    }

    & + ${CheckboxLabel} > *:last-child:not(span) {
      position: absolute;
      left: 14px;
      top: 11px;
    }
  }

  ${CheckboxInput}:focus + ${CheckboxLabel}:before, ${CheckboxLabel}:hover:before {
    border-color: ${props => props.theme.colors[props.variant || 'primary'].base};
  }

  ${outlinedStyle}
  ${indeterminatedStyle}
  ${disabledStyle}
  ${toggledStyle}
`
