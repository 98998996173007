import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import { BaseTheme, CustomTheme, DefaultTheme } from 'styled-components'

import { applyAdvancedColors } from './colors.helpers'
import { applyAdvancedSpacings } from './spacing.helpers'

export const applyCustomProperties: (
  defaultTheme: BaseTheme,
  customTheme: CustomTheme
) => BaseTheme = (defaultTheme, customTheme) => merge(cloneDeep(defaultTheme), customTheme)

export const applyAdvancedStyling: (baseTheme: BaseTheme) => DefaultTheme = baseTheme => ({
  ...baseTheme,
  colors: applyAdvancedColors(baseTheme.colors),
  ...applyAdvancedSpacings(baseTheme),
  menu: {
    ...baseTheme.menu,
    colors: applyAdvancedColors(baseTheme.menu.colors),
  },
})
