import { saveAs } from 'file-saver'

import { getSessionStorageValue } from '@procsea/common/utils'

import { APIErrorResponse, ApiPaginatedEntityResponse, isErrorResponse } from 'src/action-creators'
import { ERROR_FAIL_TO_RETRIEVE_CURRENT_MEMBERSHIP_FROM_SESSION_STORAGE } from 'src/constants/constants'
import {
  camelCasifyProperties,
  formatToFormData,
  getCSRFToken,
  snakeCasifyProperties,
} from 'src/services/Api.utils'

import { applyFetchMiddlewares } from './fetch-middlewares/applyFetchMiddlewares'

enum HttpServerErrorStatusCode {
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
}

export enum ContentType {
  APPLICATION_JSON = 'application/json',
  MULTIPART_FORM_DATA = 'multipart/form-data',
}

interface PayloadFormat {
  [ContentType.APPLICATION_JSON]: string
  [ContentType.MULTIPART_FORM_DATA]: FormData
}

export type PayloadFormatter = {
  [key in ContentType]: (payload: any) => PayloadFormat[ContentType]
}

export interface GetRequestInitArgs {
  contentType?: ContentType
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'
  payload?: unknown
  currentMembershipId?: Id
}

interface PromiseWithCancel<T> extends Promise<T> {
  cancel?: () => void
}

const payloadFormatter: PayloadFormatter = {
  [ContentType.APPLICATION_JSON]: JSON.stringify,
  [ContentType.MULTIPART_FORM_DATA]: formatToFormData,
}

export const getRequestInit = ({
  contentType = ContentType.APPLICATION_JSON,
  method,
  payload,
  currentMembershipId,
}: GetRequestInitArgs): RequestInit => {
  const sessionMembershipId = getSessionStorageValue<Id>({
    key: 'currentMembershipId',
    onError: () => {
      throw new Error(ERROR_FAIL_TO_RETRIEVE_CURRENT_MEMBERSHIP_FROM_SESSION_STORAGE)
    },
  })

  const membershipId = currentMembershipId || sessionMembershipId

  const headers = {
    'X-CSRFToken': getCSRFToken(),
    ...(!!membershipId && { 'Current-Membership': `${membershipId}` }),
    ...(contentType !== ContentType.MULTIPART_FORM_DATA && { 'Content-Type': contentType }),
    ...(window.PREVIOUS_RELEASE_TAG && { 'Previous-Release-Tag': window.PREVIOUS_RELEASE_TAG }),
    ...(window.SOURCE_VERSION && { 'Source-Version': window.SOURCE_VERSION }),
  }

  return {
    method,
    headers,
    credentials: 'include',
    body: payloadFormatter[contentType](snakeCasifyProperties(payload)),
  }
}

export const SERVER_ERROR_MESSAGE = gettext('Server responded with an error')

export const rejectErrorResponse = <T>(body: T | APIErrorResponse) =>
  isErrorResponse(body) ? Promise.reject(body) : Promise.resolve(body)

export const fetchBody = <T>(input: RequestInfo, init?: RequestInit) => {
  let abortController: AbortController | undefined

  if (init?.method === 'GET') {
    abortController = new AbortController()
    init = { ...init, signal: abortController.signal }
  }

  const promise: PromiseWithCancel<T> = fetch(input, init)
    .then(response => {
      applyFetchMiddlewares(response)

      if (Object.values(HttpServerErrorStatusCode).includes(response.status)) {
        throw new Error(SERVER_ERROR_MESSAGE)
      }

      if (response.status === 204) {
        return Promise.resolve()
      }

      return response.json()
    })
    .then(camelCasifyProperties)
    .then<T>(rejectErrorResponse)

  if (!!abortController) {
    promise.cancel = () => {
      abortController?.abort()
    }
  }

  return promise
}

interface SaveBlobArgs {
  currentMembershipId?: Id
  input: RequestInfo
}

export const saveBlob = ({ currentMembershipId, input }: SaveBlobArgs) =>
  fetch(input, getRequestInit({ method: 'GET', currentMembershipId })).then(response => {
    applyFetchMiddlewares(response)

    if (Object.values(HttpServerErrorStatusCode).includes(response.status)) {
      throw new Error(SERVER_ERROR_MESSAGE)
    }

    if (response.status !== 200) {
      return response.json().then(body => Promise.reject(body))
    }

    const filename = response.headers
      .get('Content-Disposition')
      ?.split('filename=')[1]
      .replaceAll('"', '')

    return response.blob().then(blob => saveAs(blob, filename))
  })

interface SaveBlobArgs {
  currentMembershipId?: Id
  input: RequestInfo
}

export const requestBlob = async ({ currentMembershipId, input }: SaveBlobArgs) => {
  const response = await fetch(input, getRequestInit({ currentMembershipId, method: 'GET' }))
  applyFetchMiddlewares(response)

  if (Object.values(HttpServerErrorStatusCode).includes(response.status)) {
    throw new Error(SERVER_ERROR_MESSAGE)
  }

  if (response.status !== 202) {
    const body = await response.json()

    return rejectErrorResponse(camelCasifyProperties(body))
  }

  return Promise.resolve()
}

type RecursiveInfiniteFetch = <T>(
  fetchFunction: (url: string) => Promise<ApiPaginatedEntityResponse<T>>,
  acc: T[],
  { results, next }: { results: T[]; next: string | null }
) => Promise<T[]>

export const recursiveFetchAccumulator: RecursiveInfiniteFetch = async (
  fetchFunction,
  acc,
  { results, next }
) =>
  next
    ? recursiveFetchAccumulator(fetchFunction, [...acc, ...results], await fetchFunction(next))
    : [...acc, ...results]

type InfiniteFetch = <T>(
  fetchFunction: (url: string) => Promise<ApiPaginatedEntityResponse<T>>
) => (initialUrl: string) => Promise<T[]>

export const recursiveFetch: InfiniteFetch = fetchFunction => async initialUrl =>
  recursiveFetchAccumulator(fetchFunction, [], await fetchFunction(initialUrl))
