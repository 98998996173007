import React from 'react'
import { useHistory } from 'react-router-dom'

import { Box, Button, Icon, Modal, Stack, Text, useBreakpoints } from '@procsea/design-system'

import { APPLI_URL } from 'src/constants/constants'
import { useSessionStorageContext } from 'src/contexts/SessionStorageContext'
import { useLocale } from 'src/hooks'
import { useIsSessionExpired } from 'src/main-store/MainStoreSelectors'

export const SessionExpiredModal = () => {
  const { isMobile } = useBreakpoints()
  const isSessionExpired = useIsSessionExpired()
  const locale = useLocale()
  const { setCurrentMembershipId } = useSessionStorageContext()
  const history = useHistory()

  const redirectToLogin = () => {
    setCurrentMembershipId(null)
    history.push(`/${locale}/logout`)
    window.location.href = `/${locale}${APPLI_URL.AUTH.LOGIN}`
  }

  return isSessionExpired ? (
    <Modal
      data-e2e="session-expired-modal"
      disableClose
      header={gettext('Session expired')}
      open
      width={isMobile ? '95%' : '500px'}
    >
      <Box inset="medium">
        <Stack alignment="center" spacing="large">
          <Icon faStyle="far" name="hourglass-end" size="2x" variant="neutral" />

          <Stack alignment="center" spacing="small">
            <Text variant="neutral" wrap>
              {gettext('Your session has expired.')}
            </Text>
            <Text variant="neutral" wrap>
              {gettext(
                'This can happen when you have been logged in for a long time. We do this to ensure the security of your account. Please login again.'
              )}
            </Text>
          </Stack>

          <Button
            data-e2e="session-expired-modal-login-button"
            onClick={redirectToLogin}
            variant="primary"
          >
            {gettext('Sign in')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  ) : null
}
