import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createBrowserHistory } from 'history'
import { registerLocale } from 'i18n-iso-countries'
import de from 'i18n-iso-countries/langs/de.json'
import en from 'i18n-iso-countries/langs/en.json'
import fr from 'i18n-iso-countries/langs/fr.json'
import it from 'i18n-iso-countries/langs/it.json'
import React, { Suspense, useEffect } from 'react'
import { connect } from 'react-redux'
// BrowserRouter could be extracted in index.js and changed with MemoryRouter for SSR
import { Router } from 'react-router-dom'

import FullscreenLoader from 'src/components/base/fullscreenLoader/FullscreenLoader'
import ErrorBoundary from 'src/components/presentationals/shared/errors/ErrorBoundary'
import { useUser } from 'src/contexts/userContext'
import { useSegment } from 'src/hooks/useSegment'
import { requestNotificationPermission } from 'src/utils/notifications'

import './App.css'
import Routes from './Routes'
import { AuthenticatedWithDifferentUserModal } from './components/presentationals/auth/AuthenticatedWithDifferentUserModal'
import { SessionExpiredModal } from './components/presentationals/auth/SessionExpiredModal'
import { SessionStorageProvider } from './contexts/SessionStorageContext'

const ADMIN_URL = '/admin'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 1000 * 60 * 5,
    },
  },
})

const history = createBrowserHistory()

const App = () => {
  const user = useUser()
  const { track } = useSegment()

  useEffect(() => {
    registerLocale(fr)
    registerLocale(en)
    registerLocale(de)
    registerLocale(it)

    /* Request Web Notification permission if not already refused or accepted */
    requestNotificationPermission(track)
  }, [])

  useEffect(() => {
    if (user && user.isStaff) {
      window.location.href = ADMIN_URL
    }
  }, [user])

  return (
    <SessionStorageProvider>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <Suspense fallback={<FullscreenLoader text={gettext('Loading page...')} />}>
            <ErrorBoundary>
              <Routes />
              <SessionExpiredModal />
              <AuthenticatedWithDifferentUserModal />
            </ErrorBoundary>
          </Suspense>
        </Router>

        <ReactQueryDevtools />
      </QueryClientProvider>
    </SessionStorageProvider>
  )
}

// Prepare the state & action connection
export default connect()(App)
