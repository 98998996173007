import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'

const useWindowSize = () => {
  const isClient = typeof window === 'object'

  const [state, setState] = useState({
    width: isClient ? window.innerWidth : 0,
    height: isClient ? window.innerHeight : 0,
  })

  useEffect(() => {
    let mounted = true
    const handler = () => {
      if (mounted)
        setState({
          width: window.innerWidth,
          height: window.innerHeight,
        })
    }
    const debouncedHandler = debounce(handler, 150)
    window.addEventListener('resize', debouncedHandler)

    return () => {
      mounted = false
      window.removeEventListener('resize', debouncedHandler)
    }
  }, [])

  return state
}

export default useWindowSize
