import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'

type UnaryFunction<T extends {}, R> = (args: T) => R

export const mergeParams =
  <T extends {}, R>(func: UnaryFunction<T, R>) =>
  (params1: Partial<T> = {}) =>
  (params2: T) => {
    const params1Copy = cloneDeep(params1)
    return func(merge(params1Copy, params2))
  }
