import { UseQueryOptions } from '@tanstack/react-query'

import { useEntityQuery } from 'src/queries/entity'
import {
  GenerateQueriesArgs,
  generateActionQueries,
  generateQueries,
} from 'src/queries/queryGenerator'
import { SellerCompany } from 'src/types'

export const SELLER_COMPANIES_ENDPOINT = 'seller_companies'
export const SELLER_COMPANIES_QUERY_KEY = 'sellerCompanies'

export interface UseSellerCompanyQueryArgs<TData> {
  sellerCompanyId?: Id
  queryOptions?: UseQueryOptions<SellerCompany, Error, TData>
  queryParams?: SellerCompanyQueryParams
}

export const useSellerCompanyQuery = <TData = SellerCompany>({
  sellerCompanyId,
  queryOptions,
  queryParams,
}: UseSellerCompanyQueryArgs<TData>) =>
  useEntityQuery<SellerCompany, {}, TData>({
    endpoint: SELLER_COMPANIES_ENDPOINT,
    entityId: sellerCompanyId,
    queryKey: SELLER_COMPANIES_QUERY_KEY,
    queryOptions,
    queryParams,
  })

type AscendingOrdering = 'id' | 'legal_name' | 'name'

type DescendingOrdering = `-${AscendingOrdering}`

export type SellerCompanyOrdering = AscendingOrdering | DescendingOrdering

export interface SellerCompanyQueryParams {
  buyerCompanyId?: Id | Id[]
  canUseChat?: boolean
  connected?: boolean
  connectedToGroupsIds?: Id[]
  connectedToOrganisationsIds?: Id[]
  deliveryDate?: ApiDate
  editable?: boolean
  invoicable?: boolean
  limit?: number
  offset?: number
  ordering?: SellerCompanyOrdering
  q?: string
  sellerCompanyGroupsIds?: Id[]
  verticalId?: Id[]
}

const generateQueriesConfig: GenerateQueriesArgs<SellerCompany, SellerCompanyQueryParams> = {
  endpoint: SELLER_COMPANIES_ENDPOINT,
  queryKey: SELLER_COMPANIES_QUERY_KEY,
}

export const {
  useAllEntitiesQuery: useSellerCompaniesQuery,
  usePaginatedEntitiesQuery: usePaginatedSellerCompaniesQuery,
  usePatchEntity: usePatchSellerCompany,
  usePostEntity: usePostSellerCompany,
  useRecursiveQuery: useRecursiveSellerCompaniesQuery,
} = generateQueries(generateQueriesConfig)

export const { useValidateLegalDataAction, useValidateCatalogDataAction } = generateActionQueries({
  endpoint: SELLER_COMPANIES_ENDPOINT,
  actions: ['validateLegalData', 'validateCatalogData'],
})
