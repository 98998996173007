import { Query, QueryClient, QueryKey } from '@tanstack/react-query'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'

import { findById } from 'src/utils'

import { EntityWithId, PaginatedData } from './entity.types'

export const listAllPaginatedEntities = <T>(entityPages?: PaginatedData<T>) =>
  entityPages?.pages?.map(page => page.results).reduce((acc, page) => [...acc, ...page], [])

export const getEntityCountFromLastPage = <T>(entityPages?: PaginatedData<T>) =>
  last(entityPages?.pages)?.count ?? 0

export const isListQuery = (queryKey: string) => (query: Query) =>
  query.queryKey[0] === queryKey && typeof query.queryKey[1] !== 'number'

export const createNewPaginatedCache = <Entity>(entity: Entity): PaginatedData<Entity> => ({
  pageParams: [],
  pages: [
    {
      count: 1,
      next: null,
      previous: null,
      results: [entity],
    },
  ],
})

export const appendNewEntityToPaginatedQuery = <T extends { id: Id }>({
  entityPages,
  entity,
}: {
  entityPages: PaginatedData<T>
  entity: T
}) => {
  if (entityPages.pages.length === 0) {
    return createNewPaginatedCache(entity)
  }

  const updatedEntityPages: PaginatedData<T> = {
    ...entityPages,
    pages: entityPages?.pages.map((page, index, pages) => ({
      ...page,
      count: page.count + 1,
      results: index === pages.length - 1 ? [...page.results, entity] : page.results,
    })),
  }

  return updatedEntityPages
}

export const updatePaginatedEntity = <T extends { id: Id }>({
  entityPages,
  entity,
}: {
  entityPages: PaginatedData<T>
  entity: T
}) => {
  let hasChanged = false
  const updatedEntityPages: PaginatedData<T> = {
    ...entityPages,
    pages: entityPages.pages.map(page => ({
      ...page,
      results: page.results.map(data => {
        if (data.id === entity.id && !isEqual(data, entity)) {
          hasChanged = true
          return entity
        }

        return data
      }),
    })),
  }

  if (hasChanged) return updatedEntityPages

  return undefined
}

export const deletePaginatedEntity = <T extends { id: Id }>({
  entityPages,
  entityId,
}: {
  entityPages: PaginatedData<T>
  entityId: Id
}) => {
  let hasChanged = false
  const updatedEntityPages: PaginatedData<T> = {
    ...entityPages,
    pages: entityPages?.pages.map(page => ({
      ...page,
      results: page.results.filter(spi => {
        if (spi.id === entityId) {
          hasChanged = true
          return false
        }

        return true
      }),
    })),
  }

  if (hasChanged) {
    return {
      ...updatedEntityPages,
      pages: updatedEntityPages.pages.map(page => ({ ...page, count: page.count - 1 })),
    }
  }

  return undefined
}

export const findPaginatedEntityById =
  <T extends { id: Id }>(entityPages?: PaginatedData<T>) =>
  (id?: Id): T | undefined =>
    findById(listAllPaginatedEntities(entityPages))(id)

interface FindEntityInPaginatedDataArgs<T> {
  id: Id
  paginatedData?: PaginatedData<T>
}

export const findEntityInPaginatedData = <T extends EntityWithId>({
  id,
  paginatedData,
}: FindEntityInPaginatedDataArgs<T>) => {
  if (paginatedData) {
    const pageLength = paginatedData.pages?.length ?? 0

    for (let i = 0; i < pageLength; i++) {
      const result = paginatedData.pages[i].results.find(entity => entity.id === id)

      if (result) {
        return result
      }
    }
  }

  return null
}

export interface DataFromActiveQuery<T> {
  queryKey: QueryKey
  entity?: T | null
  paginatedData: PaginatedData<T>
}

interface GetDataFromActiveQueryArgs {
  id?: Id
  queryClient: QueryClient
  queryKey: string
}

export const getDataFromActiveQuery = <T extends EntityWithId>({
  id,
  queryClient,
  queryKey,
}: GetDataFromActiveQueryArgs) => {
  const queryData =
    queryClient.getQueriesData<PaginatedData<T>>({
      type: 'active',
      exact: false,
      queryKey: [queryKey],
      predicate: isListQuery(queryKey),
    })?.[0] ?? []

  const [entityQueryKey, paginatedData] = queryData

  if (paginatedData) {
    return {
      queryKey: entityQueryKey,
      entity: id ? findEntityInPaginatedData<T>({ id, paginatedData }) : undefined,
      paginatedData,
    }
  }

  return null
}
