import mapValues from 'lodash/mapValues'
import { getLuminance, shade, tint } from 'polished'
import { DefaultTheme } from 'styled-components'

import { AdvancedColors, ColorShades, Colors, Variant } from '../types'

export const generateColorShade: (color: string) => ColorShades = color => ({
  lightest: tint(0.95, color),
  lighter: tint(0.9, color),
  light: tint(0.6, color),
  base: color,
  dark: shade(0.1, color),
  darker: shade(0.2, color),
})

export const applyAdvancedColors: (colors: Colors) => AdvancedColors = colors =>
  mapValues(colors, color => generateColorShade(color))

const DEFAULT_LUMINANCE_THRESHOLD = 0.6

export const getForegroundColor = (
  variant: Variant,
  theme: DefaultTheme,
  threshold: number = DEFAULT_LUMINANCE_THRESHOLD
) =>
  getLuminance(theme.colors[variant].base) < threshold
    ? theme.colors.white.base
    : theme.colors.black.base
