import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'

import { isObject } from 'src/utils/lang'

// @TODO: Move to src/utils folder
export const getCSRFToken = () => {
  const cook = document.cookie.split('; ').filter(cookie => cookie.split('=')[0] === 'csrftoken')[0]
  if (cook) return cook.split('=')[1]
  return ''
}

const API_DATE_REGEXP = /\d{4}-\d{2}-\d{2}/

// @TODO: Move to utils folder
export const camelCasifyProperties = (entity: unknown): any => {
  if (Array.isArray(entity)) {
    return entity.map(camelCasifyProperties)
  }

  if (isObject(entity)) {
    return Object.keys(entity).reduce(
      (acc, key) => ({
        ...acc,
        [API_DATE_REGEXP.test(key) ? key : camelCase(key)]: camelCasifyProperties(entity[key]),
      }),
      {}
    )
  }

  return entity
}

// @TODO: Move to utils folder
export const snakeCasifyProperties = (entity: unknown): any => {
  if (Array.isArray(entity)) {
    return entity.map(snakeCasifyProperties)
  }

  if (isObject(entity)) {
    return Object.keys(entity).reduce(
      (acc, key) => ({
        ...acc,
        [API_DATE_REGEXP.test(key) ? key : snakeCase(key)]: snakeCasifyProperties(entity[key]),
      }),
      {}
    )
  }

  return entity
}

// @TODO: Move to utils folder
export const formatToFormData = (payload: object) => {
  const formData = new FormData()
  Object.entries(payload).forEach(([key, value]) => {
    if (value === null) {
      formData.append(key, '')
    } else if (typeof value === 'number') {
      formData.append(key, value.toString())
    } else if (typeof value !== 'undefined') {
      formData.append(key, value)
    }
  })

  return formData
}
