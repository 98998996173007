import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Box, Button, Icon, Modal, Stack, Text, useBreakpoints } from '@procsea/design-system'

import { useSessionStorageContext } from 'src/contexts/SessionStorageContext'
import { useLocale, useSegment } from 'src/hooks'
import {
  useIsAuthenticatedWithDifferentUser,
  useMainStoreActions,
} from 'src/main-store/MainStoreSelectors'

export const AuthenticatedWithDifferentUserModal = () => {
  const { isMobile } = useBreakpoints()
  const isAuthenticatedWithDifferentUser = useIsAuthenticatedWithDifferentUser()
  const locale = useLocale()
  const { setCurrentMembershipId } = useSessionStorageContext()
  const history = useHistory()
  const { setIsAuthenticatedWithDifferentUser } = useMainStoreActions()
  const { track } = useSegment()

  useEffect(() => {
    if (isAuthenticatedWithDifferentUser) {
      track('Show modal - Authenticated with a different user')
    }

    const handleBeforeUnload = () => {
      if (isAuthenticatedWithDifferentUser) {
        setCurrentMembershipId(null)
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isAuthenticatedWithDifferentUser])

  const refreshPage = () => {
    setCurrentMembershipId(null)
    setIsAuthenticatedWithDifferentUser(false)
    history.push(`/${locale}/`)
    window.location.href = `/${locale}/`
  }

  return isAuthenticatedWithDifferentUser ? (
    <Modal
      data-e2e="authenticated-with-different-user-modal"
      disableClose
      header={gettext('Authenticated with a different user')}
      open
      width={isMobile ? '95%' : '500px'}
    >
      <Box inset="medium">
        <Stack alignment="center" spacing="large">
          <Icon faStyle="far" name="user-xmark" size="2x" variant="neutral" />
          <Stack alignment="center" spacing="small">
            <Text variant="neutral" wrap bold>
              {gettext('Wrong user')}
            </Text>
            <Text variant="neutral" wrap>
              {gettext(
                'You are currently authenticated with a different user, please reload the application.'
              )}
            </Text>
          </Stack>

          <Button
            data-e2e="authenticated-with-different-user-modal-reload-button"
            onClick={refreshPage}
            variant="primary"
          >
            {gettext('Reload')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  ) : null
}
