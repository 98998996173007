import { checkAuthenticatedWithDifferentUserMiddleware } from './checkAuthenticatedWithDifferentUserMiddleware/checkAuthenticatedWithDifferentUserMiddleware'
import { checkSessionExpiredMiddleware } from './checkSessionExpiredMiddleware/checkSessionExpiredMiddleware'
import { FetchMiddleware } from './fetchMiddleware.types'

const middlewares: FetchMiddleware[] = [
  checkSessionExpiredMiddleware,
  checkAuthenticatedWithDifferentUserMiddleware,
]

export const applyFetchMiddlewares = (response: Response) => {
  middlewares.reduce((currentResponse, middleware) => middleware(currentResponse), response)
}
